<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <div v-if="!loading">

            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button
                        @click="$root.$children[0].openModal('kbkonto-modal', {id: 0}, afterSave, {width: '800px'})"
                        variant="primary"
                        class="mb-2"
                    >
                        <font-awesome-icon icon="plus"/>
                        {{ $t('common.button.create') }}
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">

                    <b-table-simple striped>
                        <b-thead head-variant="info">
                            <b-th>{{ $t('settings.kbkontos.label.id') }}</b-th>
                            <b-th>{{ $t('settings.kbkontos.label.title') }}</b-th>
                            <b-th>{{ $t('settings.kbkontos.label.created_at') }}</b-th>
                            <b-th>{{ $t('settings.kbkontos.label.actions') }}</b-th>
                        </b-thead>
                        <draggable :class="{[`cursor-grabbing`]: dragging === true}" v-model="kbkontos"
                                   group="columns" @start="dragging = true" @end="dragging = false" tag="tbody"
                                   handle=".handle">
                            <b-tr v-for="kbkonto in kbkontos" :key="kbkonto.id">
                                <b-td>{{ kbkonto.id }}</b-td>
                                <b-td>{{ kbkonto.title }}</b-td>
                                <b-td>{{ kbkonto.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}</b-td>
                                <b-td>
                                    <b-button
                                        @click="$root.$children[0].openModal('kbkonto-modal', {id: kbkonto.id},afterSave,{width: '800px'})"
                                        size="sm"
                                        variant="primary"
                                        class="mr-2"
                                        :title="$t('common.title.edit')"
                                    >
                                        <font-awesome-icon icon="edit"/>
                                    </b-button>

                                    <b-button @click="deleteKbkonto(kbkonto.id)"
                                              size="sm"
                                              variant="danger"
                                              :title="$t('common.title.delete')"
                                    >
                                        <font-awesome-icon icon="trash"/>
                                    </b-button>
                                </b-td>
                            </b-tr>
                        </draggable>
                    </b-table-simple>
                </b-col>
            </b-row>
        </div>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import Draggable from "vuedraggable"

export default {
    name: 'Settings-kbkonto',
    components: {
        Draggable
    },
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            kbkontos: [],
            dragging: false,
        }
    },
    methods: {
        ...mapGetters('Kbkonto', ['getKbkontos']),
        shown() {
            const kbkontosFetch = this.$store.dispatch('Kbkonto/fetchKbkontos')

            Promise.all([kbkontosFetch])
                .then(() => {
                    this.kbkontos = this.getKbkontos()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        afterSave() {
            this.$root.afterSettingsUpdate()
            this.shown()
        },
        deleteKbkonto(id) {
            this.$removeConfirm('Kbkonto/deleteKbkonto', id, this.shown)
        }
    },
    mounted() {
        this.shown()
    }
}
</script>